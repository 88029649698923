import { isTocPackage } from "./utils";

const HIVEX_SUFFIX = 'Hivex';
const FSV_SUFFIX = 'FSV';

function getIndex({ defaultIndex, type, sphere, cylinder, addOnPackage, addOn8 }) {
  const index = { value: defaultIndex || 1.6, suffix: '' };
  const abs = Math.abs(sphere) + Math.abs(cylinder);

  // For rimless and half rim (Irrespective of packages):

  if ((type === 'Rimless' || type === 'Half Rim')) {
    if (addOnPackage?.toLowerCase()?.includes('elite')) {
      if (abs <= 6 ) {
        index.value = 1.6;
        index.suffix=HIVEX_SUFFIX;
      } else if (abs > 6 && abs <= 8) {
        index.value = 1.67;
      }else if (abs > 8) {
        index.value = 1.74;
      }
      return index;
    }
    if(addOnPackage?.toLowerCase()?.includes('ultra')){
      index.value = 1.67;
      if (abs > 8) {
        index.value = 1.74;
      }
      return index;
    }
    
    index.value = 1.6;
    if (abs > 6) {
      index.value = 1.67;
    }else {
      index.suffix=HIVEX_SUFFIX;
    }

    return index;
  }

  // For Essential:

  if (addOnPackage?.toLowerCase() === 'essential') {
    index.value = 1.56;
    return index;
  }

  // For Exceptional, Digital Protect, Standard and Premier:

  if (addOnPackage?.toLowerCase() === 'exceptional' ||
    addOnPackage?.toLowerCase() === 'digital protect' ||
    addOnPackage?.toLowerCase() === 'standard' ||
    addOnPackage?.toLowerCase() === 'premier') {
      if (abs <= 3) {
        index.value = 1.56;
      } else if (abs > 3 && abs <= 5) {
        index.value = 1.6;
      } else if (abs > 5 && abs <= 8) {
        index.value = 1.67;
      }

      return index;
  }

  // For Elite and Ultra:

//   For elite and ultra:
// Eyewa Index Calculation
// abs(SPH) + abs(CYL) <=5 - 1.6 FSV
// abs(SPH) + abs(CYL) >5 & <=8 - 1.67 FSV
// abs(SPH) + abs(CYL) >8 - 1.74 FSV
// Rimless and half rim frames; Hi-vex: SPH -4 to +4, CYL 0 to - 2 

  if (addOnPackage?.toLowerCase()?.includes('elite') ||
    addOnPackage?.toLowerCase()?.includes('ultra')) {
    if (abs <= 5 ) {
      index.value = 1.6;
      if(addOnPackage?.toLowerCase()?.includes('ultra')){
        index.value = 1.67;
      }
    }
    else if (abs > 5 && abs <= 8) {
      index.value = 1.67;
    }
    else if (abs > 8) {
      index.value = 1.74;
    }
    
    return index;
  }

  // BELOW this is probably unused --------------------------

  if (isTocPackage(addOnPackage)) {
    if (type === 'Rimless' ) {
      index.value = 1.6;
      index.suffix = HIVEX_SUFFIX;// + ' ' + FSV_SUFFIX;
    } else {
      //index.suffix = FSV_SUFFIX;
      if (addOnPackage?.toLowerCase() === 'essential') {
        index.value = 1.56;
      } else if (addOn8 === 'Basic') {
        index.value = 1.56;
      } else if (addOn8 === 'Thin') {
        index.value = abs <= 3 ? 1.56 : 1.6;
      } else if (addOn8 === 'Thinnest') {
        index.value = abs <= 8 ? 1.67 : 1.74;
      }
    }
    return index;
  }

  if (
    (type === 'Rimless' || type === 'Half Rim') &&
    sphere >= -4 &&
    sphere <= 4 &&
    cylinder >= -2 &&
    cylinder <= 0
  ) {
    index.value = 1.6
    index.suffix = HIVEX_SUFFIX;// + ' ' + FSV_SUFFIX
  } else {
    //index.suffix = FSV_SUFFIX;
    if (abs <= 3) {
      index.value = (addOnPackage?.includes('Elite') || addOnPackage?.includes('Ultra')) ? 1.67 : 1.56;
    } else if (abs > 3 && abs <= 5) {
      index.value = (addOnPackage?.includes('Elite') || addOnPackage?.includes('Ultra')) ? 1.67 : 1.6;
    } else if (abs > 5 && abs <= 8) {
      index.value = 1.67;
    } else if (abs > 8) {
      index.value = 1.74;
    }
  }

  return index;
}

function calculateIndex({ defaultIndex, type, left, right, lensType, addOnPackage, addOn8 }) {
  const commonArgs = { defaultIndex, type, lensType, addOnPackage, addOn8 };

  const leftIndex = getIndex({
    ...commonArgs,
    sphere: parseFloat(left?.sphere),
    cylinder: parseFloat(left?.cylinder),
  });

  const rightIndex = getIndex({
    ...commonArgs,
    sphere: parseFloat(right?.sphere),
    cylinder: parseFloat(right?.cylinder),
  });

  const greaterIndex = leftIndex.value > rightIndex.value ? leftIndex : rightIndex;
  return `${parseFloat(greaterIndex.value).toFixed(2)} ${greaterIndex.suffix}`;
}

export default calculateIndex;
