import React from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import cx from 'classnames'
import styles from './FormElements.module.scss';
import { useId } from 'react';
import Skeleton from 'components/skeleton/Skeleton';

const ARROW = <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M0.52827 0.52827C0.788619 0.26792 1.21073 0.26792 1.47108 0.52827L4.99967 4.05687L8.52827 0.52827C8.78862 0.26792 9.21073 0.26792 9.47108 0.52827C9.73143 0.788619 9.73143 1.21073 9.47108 1.47108L5.47108 5.47108C5.21073 5.73143 4.78862 5.73143 4.52827 5.47108L0.52827 1.47108C0.26792 1.21073 0.26792 0.788619 0.52827 0.52827Z" fill="#31395F"/> </svg>
export const COUNTRIES = i18n => [
  { value: 'ae', code: 'ARE', label: i18n('United Arab Emirates'), flag: require('./flags/ae.png') },
  { value: 'sa', code: 'SAU', label: i18n('Saudi Arabia'), flag: require('./flags/sa.png') },
  { value: 'om', code: 'OMN', label: i18n('Oman'), flag: require('./flags/om.png') },
  { value: 'bh', code: 'BHR', label: i18n('Bahrain'), flag: require('./flags/bh.png') },
  { value: 'kw', code: 'KWT', label: i18n('Kuwait'), flag: require('./flags/kw.png') },
  { value: 'qa', code: 'QAT', label: i18n('Qatar'), flag: require('./flags/qa.png') },
];

function Countries({ onChange, error, className, value, i18n, loading }) {
  const id = useId();

  return (
    <div className={cx(styles.field, className, { [styles.error]: !!error } )}>
      <label className={cx(styles.label)} htmlFor={id}>{i18n('Country')}</label>
      {loading ? <Skeleton type="input" /> : (
        <Select
          isSearchable={false}
          unstyled
          id={id}
          placeholder={i18n('Select')}
          components={{
            DropdownIndicator: () => ARROW,
            Option: props => (
              <components.Option {...props}>
                <img src={props.data.flag} alt="" />
                {props.data.label}
              </components.Option>
            )
          }}
          classNames={{
            control: () => cx(styles['select-control'], styles.input),
            menu: () => cx(styles['select-menu']),
            option: state => cx(styles['select-option'], { [styles.selected]: state.isSelected }),
          }}
          options={COUNTRIES(i18n)}
          value={COUNTRIES(i18n).find(x => x.value === value)}
          onChange={e => onChange(e.value)}
        />
      )}
      {error && <label htmlFor={id} className={cx(styles.message)}>{error}</label>}
    </div>
  )
}

Countries.defaultProps = {
  i18n: val => val
}

Countries.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  i18n: PropTypes.func,
  loading: PropTypes.bool
}

export default Countries
