import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Events.module.scss'
import useI18n from 'common-hooks/useI18n'
import Button from 'components/button/Button'
import dayjs from 'dayjs'
import { useLazyCancelAppointmentQuery, useLazyMarkAppointShowQuery } from 'app/service'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { selectDefaults } from 'appSlice'

const ICON = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.00021 2C7.55251 2 8.00033 2.44764 8.00033 2.99994V3.99988H16V2.99994C16 2.44764 16.4478 2 17.0001 2C17.5524 2 18.0001 2.44764 18.0001 2.99994V3.99988H19C20.657 3.99988 22 5.34302 22 6.99987V14.0001C22 14.5524 21.5524 15.0001 21.0001 15.0001C20.4478 15.0001 20.0001 14.5524 20.0001 14.0001V10H3.99988V19C3.99988 19.5523 4.44769 20.0001 4.99999 20.0001H9.99969C10.552 20.0001 10.9998 20.4478 10.9998 21.0001C10.9998 21.5524 10.552 22 9.99969 22H4.99999C3.34314 22 2 20.657 2 19V7.00004C2 5.34319 3.34314 4.00005 4.99999 4.00005H5.99993V3.00011C5.99993 2.44781 6.44757 2.00017 6.99987 2.00017L7.00021 2ZM5.00033 5.99993C4.44804 5.99993 4.00022 6.44757 4.00022 6.99987V7.99998H20.0005V6.99987C20.0005 6.44757 19.5527 5.99993 19.0004 5.99993H5.00033Z" fill="white"/>
    <path d="M18.0781 17.5C17.6927 17.1147 17.6927 16.4899 18.0781 16.1045C18.4634 15.7191 19.0882 15.7191 19.4736 16.1045L21.4473 18.0782C21.8325 18.4634 21.8325 19.0882 21.4473 19.4736L19.4736 21.4473C19.0882 21.8326 18.4634 21.8326 18.0781 21.4473C17.6927 21.0619 17.6927 20.4371 18.0781 20.0517L19.354 18.7758L18.0781 17.5Z" fill="white"/>
    <path d="M13.1445 16.1045C12.7591 16.4899 12.7591 17.1147 13.1445 17.5L14.4204 18.7758L13.1445 20.0517C12.7591 20.4371 12.7591 21.0619 13.1445 21.4473C13.53 21.8326 14.1548 21.8326 14.54 21.4473L16.5137 19.4736C16.8991 19.0882 16.8991 18.4634 16.5137 18.0782L14.54 16.1045C14.1547 15.7191 13.5299 15.7191 13.1445 16.1045Z" fill="white"/>
  </svg>
)
const ARROW_DOWN = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.25977 0.779297L6.24023 5.75977L11.2207 0.779297" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round"/> </svg>
const ARROW_UP = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.7402 6.2207L5.75977 1.24023L0.779297 6.2207" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round"/> </svg>
const CHECK_ICON = <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 5.93359L6.56633 10.4996" stroke="#31395F" strokeWidth="2.5" strokeLinecap="round"/> <path d="M15.7001 1.36719L6.56641 10.5002" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round"/> </svg>
const CROSS_ICON = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.75 1.75L12.25 12.25" stroke="#E52D42" strokeWidth="2" strokeLinecap="round"/> <path d="M12.25 1.75L1.75 12.25" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/> </svg>

function Event({ appointmentId, show, status: appointmentStatus, fromDate, toDate, createdDate, note, customer }) {
  const { id, email, firstName, lastName, gender, dob } = customer || {};
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const { country, defaultWarehouse } = useSelector(selectDefaults);
  const [expanded, setExpanded] = useState(false);
  const [status, setStatus] = useState(show);
  const [statusToUpdate, setStatusToUpdate] = useState();
  const [cancelled, setCancelled] = useState(appointmentStatus === 'CANCELLED');
  const [mark, {isLoading: updatingStatus}] = useLazyMarkAppointShowQuery();
  const [cancel, { isLoading: cancelling }] = useLazyCancelAppointmentQuery();
  const renderInfo = (label, value) => (
    <p className={cx(styles['info-item'])}>
      <span className={cx(styles['info-label'])}>{i18n(label)}</span>
      <strong className={cx(styles['info-value'])}>{value || '-'}</strong>
    </p>
  )

  const handleAddPrescription = () => {
    navigate(`/customers/${id}/prescriptions`, { state: { appointmentId } });
  }

  const handleAddCeeReport= () => {
    navigate(`/customers/${id}/prescriptions/add-cee`, { state: { appointmentId } });
  }

  const handleMarkAppointmentShow = async show => {
    setStatusToUpdate(show);
    await mark({ appointmentId, show, country })
    setStatus(show);
  }

  const handleCancel = async () => {
    await cancel({ appointmentId, country });
    setCancelled(true);
  }

  const renderStatus = (icon, label, className) => (
    <div className={cx(styles.status, styles[className])}>
      {icon}
      {i18n(label)}
    </div>
  )

  const nonCancelledActions = status === null && appointmentStatus !== 'MISSED' && appointmentStatus !== 'COMPLETE' ? (
    <>
      {/* <Button loading={statusToUpdate === true && updatingStatus} onClick={() => handleMarkAppointmentShow(true)} className={cx(styles['main-action'])} theme="secondary">{i18n('Start eye test')}</Button> */}
      <Button loading={statusToUpdate === false && updatingStatus} onClick={() => handleMarkAppointmentShow(false)} className={cx(styles['main-action'])} theme="secondary">{i18n('Mark as no-show')}</Button>
      <button loading={!!cancelling} type="button" onClick={handleCancel} className={cx(styles.cancel)}>{CROSS_ICON} {i18n('Cancel')}</button>
    </>
  ) : (
    appointmentStatus === 'MISSED' || !status ? (
      renderStatus(CROSS_ICON, 'No Show', 'no-show')
    ) : (
      renderStatus(CHECK_ICON, 'Complete', 'complete')
    )
  )

  return (
    <li className={cx(styles.event)}>
      <div className={cx(styles['event-summary'])}>
        <span className={cx(styles.icon)}>{ICON}</span>
        <time className={cx(styles.time)}>{`${dayjs(fromDate).format('HH:mm')} - ${dayjs(toDate).format('HH:mm')}`}</time>
        <span className={cx(styles['name-location'])}>
          <span className={cx(styles.name)}>{[firstName, lastName].filter(Boolean).join(' ')}</span>
          <span className={cx(styles.location)}>{i18n('In store')}</span>
        </span>
        <button className={cx(styles.trigger)} onClick={() => setExpanded(!expanded)}>
          {i18n('Details')}
          {expanded ? ARROW_UP : ARROW_DOWN}
        </button>
      </div>
      {expanded && <div className={cx(styles['event-details'])}>
        <div className={cx(styles.actions)}>
          {!cancelled && <Button onClick={handleAddPrescription} className={cx(styles['main-action'])} theme="secondary">{i18n('Add prescription')}</Button>}
          {!cancelled && defaultWarehouse?.comprehensiveETBEnabled && <Button onClick={handleAddCeeReport} className={cx(styles['main-action'])} theme="secondary">{i18n('Add CEE report')}</Button>}
          {!cancelled ? nonCancelledActions : renderStatus(CROSS_ICON, 'Cancelled', 'no-show')}
        </div>
        <div className={cx(styles.infos)}>
          {renderInfo('Email', email)}
          {renderInfo('Gender', gender)}
          {renderInfo('Created date', createdDate)}
          {renderInfo('Date of birth', dob)}
          {renderInfo('Note', note)}
        </div>
      </div>}
    </li>
  )
}

Event.propTypes = {
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  createdDate: PropTypes.string,
  isPast: PropTypes.bool,
  note: PropTypes.string,
  customer: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    dob: PropTypes.string,
    gender: PropTypes.string
  })
}

export default Event
