import React, { useEffect, useMemo, useState } from 'react'
import useI18n from 'common-hooks/useI18n'
import cx from 'classnames'
import styles from './EyeTestsPerformanceDashboard.module.scss'
import TabularSummary from 'components/tabular-summary/TabularSummary'
import { useSelector } from 'react-redux'
import { selectToken } from 'features/login/loginSlice'
import { selectDefaults } from 'appSlice'
import { useLazyGetPerformanceMetricsQuery } from 'app/service'
import dayjs from 'dayjs'
import { Input, Layout, SimpleSelect, Skeleton } from 'components'
import "react-datepicker/dist/react-datepicker.css";
import { components } from 'react-select'
import DateRangePicker from './components/DateRangePicker'
import CustomerTable from 'pages/dashboard/CustomerTable'
import { useLocation } from 'react-router'

function EyeTestsPerformanceDashboard() {
  const { i18n } = useI18n();
  const { pathname, search } = useLocation();
  const token = useSelector(selectToken)
  const { storeName, country } = useSelector(selectDefaults);
  const [getPerformanceMatrix, { isLoading, isFetching, error, data: performanceMatrixData }] = useLazyGetPerformanceMetricsQuery()
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null)
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);


  const onDateChange = (dates) => {
    const { start, end } = dates;
    let formattedStart = dayjs(start).format('DD-MM-YYYY')
    let formattedEnd = dayjs(end).format('DD-MM-YYYY')
    setStartDate(formattedStart);
    setEndDate(formattedEnd);
  };

  useEffect(() => {
    if (token) {
      const today = dayjs()
      const oneDayAgo = today.subtract(1, 'day')
      getPerformanceMatrix({ country, endDate: today.format('DD-MM-YYYY'), startDate: oneDayAgo.format('DD-MM-YYYY') })
    }
  }, [token])

  useEffect(() => {
    if (!startDate || !endDate) return
    getPerformanceMatrix({ country, endDate, startDate })
  }, [startDate, endDate])

  const calcChangeAndLabel = (current, previous) => {
    try {
      if (previous === 0) {
        return {
          percent: '',
          label: 'previous was 0',
          growth: 'positive'
        }
      }
      let change = ((current - previous) / previous) * 100
      return {
        percent: change.toFixed(2) + '%',
        label: ' change',
        growth: change > 0 ? 'positive' : 'negative'
      }
    } catch (error) {
      return {
        percent: '-',
        label: '- change',
        growth: 'neutral'
      }
    }
  }

  const getSortedByDate = useMemo(() => {
    return performanceMatrixData?.customerData?.slice()?.sort((a, b) => (new Date(b.lastEyeTestDate.split('-').reverse().join('/'))).getTime() - (new Date(a.lastEyeTestDate.split('-').reverse().join('/'))).getTime())
  }, [performanceMatrixData?.customerData])

  let tableItems = () => {
    let currentData = performanceMatrixData?.currentPeriodData?.store?.[0] ?? {}
    let previousData = performanceMatrixData?.previousPeriodData?.store?.[0] ?? {}
    return [
      { term: i18n('Accepted'), def: currentData.acceptance, change: calcChangeAndLabel(currentData.acceptance, previousData.acceptance) },
      { term: i18n('Canceled'), def: currentData.cancellation, change: calcChangeAndLabel(currentData.cancellation, previousData.cancellation) },
      { term: i18n('Missed'), def: currentData.missed, change: calcChangeAndLabel(currentData.missed, previousData.missed) },
      // { term: i18n('Overall eye tests'), def: currentData.overall_eye_test, change: calcChangeAndLabel(currentData.overall_eye_test, previousData.overall_eye_test) },
    ]
  }

  let eyeTestTableItems = () => {
    let currentData = performanceMatrixData?.currentPeriodData?.store?.[0] ?? {}
    let previousData = performanceMatrixData?.previousPeriodData?.store?.[0] ?? {}
    return [
      { term: i18n('No. of Eyetest'), def: currentData.total_eye_test, change: calcChangeAndLabel(currentData.total_eye_test, previousData.total_eye_test) },
      { term: i18n('Peak hour'), def: currentData.peak_hour_performance, change: calcChangeAndLabel(currentData.peak_hour_performance, previousData.peak_hour_performance) },
      { term: i18n('No. of Lenses'), def: currentData.lenses_sold, change: calcChangeAndLabel(currentData.lenses_sold, previousData.lenses_sold) },
      { term: i18n('No. of PG Lens'), def: currentData.pg_lenses_sold, change: calcChangeAndLabel(currentData.pg_lenses_sold, previousData.pg_lenses_sold) },
    ]
  }

  let ordersSummaryTable = () => {
    let currentData = performanceMatrixData?.currentPeriodData?.store?.[0] ?? {}
    let previousData = performanceMatrixData?.previousPeriodData?.store?.[0] ?? {}
    return [
      { term: i18n('AOV'), def: currentData.average_order_value, change: calcChangeAndLabel(currentData.average_order_value, previousData.average_order_value) },
      { term: i18n('No. of Orders'), def: currentData.no_of_orders, change: calcChangeAndLabel(currentData.no_of_orders, previousData.no_of_orders) },
      { term: i18n('Total sales'), def: currentData.total_sales, change: calcChangeAndLabel(currentData.total_sales, previousData.total_sales) },
      { term: i18n('Conversion'), def: currentData.conversion + '%', change: calcChangeAndLabel(currentData.conversion, previousData.conversion) },
    ]
  }

  const options = [
    { value: 'lastDay', label: 'Last Day' },
    { value: 'lastWeek', label: 'Last Week' },
    { value: 'lastMonth', label: 'Last Month' },
    { value: 'customDateRange', label: 'Custom Date Range' },
  ];

  const handleChange = (option) => {
    setSelectedOption(option);
    if (option !== 'customDateRange') {
      if (option === 'lastDay') {
        const today = dayjs()
        const oneDayAgo = today.subtract(1, 'day')
        onDateChange({ start: oneDayAgo, end: today })
      } else if (option === 'lastWeek') {
        const today = dayjs()
        const oneWeekAgo = today.subtract(7, 'day')
        onDateChange({ start: oneWeekAgo, end: today })
      } else if (option === 'lastMonth') {
        const today = dayjs()
        const oneMonthAgo = today.subtract(30, 'day')
        onDateChange({ start: oneMonthAgo, end: today })
      }
    } else {
      setShowDatePicker(true)
    }
  };

  const handleDateRangeChange = (range) => {
    if (range?.startDate && range?.endDate) {
      setShowDatePicker(false)
      onDateChange({ start: range?.startDate, end: range?.endDate })
    }
  };

  const CustomOption = ({ children, ...props }) => {
    return <components.Option {...props}>{children}</components.Option>;
  };


  const renderLink = cell => {
    const base = window.location.href.split(pathname)[0];
    return <a target={'_blank'} rel='noreferrer' className={styles.link} href={`${base}/customers/${cell.row.original.customerId}/prescriptions/`}>{cell.value}</a>
  }

  const filterFutureDates = (date) => {
    return date < new Date()
  }

  const columns = useMemo(() => [
    { Header: i18n('Customer Id'), accessor: 'customerId', Cell: renderLink },
    { Header: i18n('Customer Name'), accessor: 'customerName' },
    { Header: i18n('Eye Test Count'), accessor: 'eyeTestCount' },
    { Header: i18n('Last Eye Test Date'), accessor: 'lastEyeTestDate' },
    { Header: i18n('Orders'), accessor: 'ordersCount' },
    { Header: i18n('Phone'), accessor: 'phoneNumber' },
  ], []);


  const Filter = () => {
    return (
      <div className={cx(styles.filter)}>
        <div>
          <SimpleSelect
            components={{ Option: CustomOption }}
            value={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder="Filter"
          />
          {selectedOption && selectedOption === 'customDateRange' && showDatePicker && (
            <div className={styles.dateRangeWrapper}>
              <DateRangePicker onChange={handleDateRangeChange} filterDate={filterFutureDates} />
            </div>
          )}
        </div>
        {startDate && endDate && <Input disabled={true} value={`${startDate} to ${endDate}`} className={styles.dateDisplay} />}
      </div>
    )
  }

  return (
    <Layout title={i18n('Eye Tests Performance')} wide >
      <h2>Filters</h2>
      <Filter />
      <div>
        {/* <div className={styles.metricsWrapper}>
          <div className={styles.tabularSummaryWrapper}>
            <h3 className={styles.metricHeading}>Store eyetest booking summary</h3>
            <TabularSummary
              // title={store?.store_code}
              items={tableItems()}
              isMetricsView
              grid2
            />
          </div>

          <div className={styles.tabularSummaryWrapper}>
            <h3 className={styles.metricHeading}>Eyetest summary</h3>
            <TabularSummary
              // title={store.store_code}
              items={eyeTestTableItems()}
              isMetricsView
              grid2
            />
          </div>

          <div className={styles.tabularSummaryWrapper}>
            <h3 className={styles.metricHeading}>Orders summary</h3>
            <TabularSummary
              // title={store.store_code}
              items={ordersSummaryTable()}
              isMetricsView
              grid2
            />
          </div>
        </div> */}
      </div>
      <div>
        <h2>Customers</h2>
        {(isLoading || isFetching || !performanceMatrixData) ? (
          <Skeleton type="prescriptions" />
        ) : (
          <CustomerTable columns={columns} data={getSortedByDate} noRedirect />
        )}
      </div>
    </Layout>
  )
}

EyeTestsPerformanceDashboard.propTypes = {}

export default EyeTestsPerformanceDashboard
