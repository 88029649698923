import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState, useId } from 'react'
import useI18n from 'common-hooks/useI18n';
import styles from './../StoreAnalytics.module.scss'
import { useSelector } from 'react-redux';
import { selectDefaults, selectStores } from 'appSlice';
import Table from 'components/table/Table';
import { useFetchAppointmentsCustomerSupportMutation, useLazyCancelAppointmentQuery, useLazyConfirmAppointmentQuery } from 'app/service';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { Button, Input, SimpleSelect } from 'components';
import DatePicker from "react-datepicker";
import Section from 'components/section/Section';
import CalendarIcon from './../../../icons/solar_calendar-linear.svg'
import { useDebouncedCallback } from 'use-debounce'
import cx from 'classnames';
import PopUpDialog from 'components/pop-up/PopUp';

const STATUS_OPTIONS = [
  { label: 'Missed', value: 'MISSED' },
  { label: 'Booked', value: 'BOOKED' },
  { label: 'Confirmed', value: 'CONFIRMED' },
  { label: 'Cancelled', value: 'CANCELLED' },
];

const defaultFilters = {
  store: '',
  status: '',
  fromDate: new Date(dayjs().subtract(1, 'day').format('YYYY-MM-DD')),
  toDate: new Date(dayjs().format('YYYY-MM-DD')),
  term: '',
}

const DEFAULT_PAGE_SIZE = 10;

function StoreAppointments() {
  const { i18n } = useI18n();
  const searchRef = useRef();
  const dialogRef = useRef(null);
  const id = useId();

  const stores = useSelector(selectStores);
  const { country } = useSelector(selectDefaults);

  const [fetch, { data = [], isLoading }] = useFetchAppointmentsCustomerSupportMutation();
  const [cancel] = useLazyCancelAppointmentQuery();
  const [confirm] = useLazyConfirmAppointmentQuery();

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [cancelling, setCancelling] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [cancelReason, setCancelReason] = useState('');

  const handleCancel = async (appointmentId) => {
    try {
      if (!cancelReason) {
        toast.error('Please provide a reason for cancellation');
        return;
      }
      setCancelling(true);
      await cancel({ appointmentId, country, reason: cancelReason });
      toast.success('Appointment cancelled successfully');
      handleFetch();
    } catch (error) {
      // toast.error('Failed to cancel appointment');
    } finally {
      setSelectedAppointment(null);
      setCancelling(false);
      setCancelReason('');
      dialogRef.current.close();
    }
  }

  const handleConfirm = async (appointmentId) => {
    try {
      setCancelling(true);
      await confirm({ appointmentId, country });
      toast.success('Appointment confirmed successfully');
      handleFetch();
    } catch (error) {
      // toast.error('Failed to confirm appointment');
    } finally {
      setSelectedAppointment(null);
      setCancelling(false);
      dialogRef.current.close();
    }
  }

  const handleDialog = (type, appointmentId) => {
    if (dialogRef.current) {
      dialogRef.current.showModal();
      setSelectedAppointment(appointmentId);
      setDialogType(type);
    }
  }

  const handleSearch = useDebouncedCallback(e => {
    handleFilterChange('term', e.target.value);
  }, 300);

  const handleFilterChange = (name, value) => {
    if (name === 'store') {
      value = stores.find(x => x.value === value)?.storeCode;
    }
    setFilters({ ...filters, [name]: value});
    setPage(0);
  }

  const columns = useMemo(() => [
    {
      Header: 'Name', accessor: 'customer', Cell: ({ row }) => (
        <div>{row.original.customer.firstName} {row.original.customer.lastName}</div>
      )
    },
    { Header: 'Phone Number', accessor: 'customer.phone' },
    {
      Header: 'Appointment Date & Time', accessor: 'fromDate', Cell: ({ row }) => (
        <div>{dayjs(row.original.fromDate).format('DD-MMM-YYYY HH:mm A')}</div>
      )
    },
    { Header: 'Store', accessor: 'store.code' },
    {
      Header: 'Appointment Status', accessor: 'status', Cell: ({ value }) =>
        <div className={cx(styles.appointmentStatus, styles[value.toLowerCase()])}>{value.toLowerCase()}</div>
    },
    {
      Header: 'Reason', accessor: 'note'
    },
    {
      Header: 'Actions', accessor: 'actions', Cell: ({ row }) => (
        <div className={styles.actionWrapper}>
          <Button disabled={row.original.status === 'CONFIRMED' || row.original.status === 'CANCELLED' || row.original.status === 'MISSED' || row.original.status === 'COMPLETED'} className={styles.actionButton} size='extra-small' type='button' onClick={() => handleDialog('confirm', row.original.appointmentId)}>Confirm</Button>
          <Button disabled={row.original.status === 'CANCELLED' || row.original.status === 'COMPLETED'} className={styles.actionButton} size='extra-small' type='button' theme='secondary' onClick={() => handleDialog('cancel', row.original.appointmentId)}>Cancel</Button>
        </div>
      ),
    }
  ], []);

  const handleFetch = useCallback(() => {
    const body = {
      country,
      fromTime: filters.fromDate ? dayjs(filters.fromDate).format('YYYY-MM-DDTHH:mm:ss') : undefined,
      toTime: filters.toDate ? dayjs(filters.toDate).format('YYYY-MM-DDTHH:mm:ss') : undefined,
      storeId: filters.store,
      status: filters.status,
      term: filters.term,
      size: DEFAULT_PAGE_SIZE,
      page: page
    }
    if (country) fetch(body)
  }, [country, fetch, filters, page]);

  useEffect(() => {
    handleFetch()
  }, [country, page, filters, handleFetch]);

  const DateCustomInput = forwardRef(
    ({ value, onClick, label, placeholderText }, ref) => (
      <>
        <label className={styles.label} htmlFor={`${id}-${label}`}>{label}</label>
        <button id={`${id}-${label}`} type='button' className={styles.date} onClick={onClick} ref={ref} placeholder={placeholderText}>
          {value}
        </button>
        <img src={CalendarIcon} className={styles.icon} alt='Calendar' />
      </>
    ),
  );

  return (
    <div>
      <h1 className={styles.heading}>{'Manage appointments'}</h1>
      <Section title={''}>
        <form>
          <div className={styles.search}>
            <label htmlFor="appointment-search" className={styles.label}>Filter or search</label>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 8.75008C1.66602 4.83806 4.83733 1.66675 8.74935 1.66675C12.6614 1.66675 15.8327 4.83806 15.8327 8.75008C15.8327 12.6621 12.6614 15.8334 8.74935 15.8334C4.83733 15.8334 1.66602 12.6621 1.66602 8.75008ZM8.74935 3.33341C5.75781 3.33341 3.33268 5.75854 3.33268 8.75008C3.33268 11.7416 5.75781 14.1667 8.74935 14.1667C11.7409 14.1667 14.166 11.7416 14.166 8.75008C14.166 5.75854 11.7409 3.33341 8.74935 3.33341Z" fill="#7A8AB2" /> <path fillRule="evenodd" clipRule="evenodd" d="M12.5781 12.5773C12.9035 12.2519 13.4311 12.2519 13.7566 12.5773L18.0899 16.9107C18.4153 17.2361 18.4153 17.7637 18.0899 18.0892C17.7645 18.4146 17.2368 18.4146 16.9114 18.0892L12.5781 13.7558C12.2526 13.4304 12.2526 12.9028 12.5781 12.5773Z" fill="#7A8AB2" /> </svg>
            <input name='appointment-search' ref={searchRef} onChange={handleSearch} className={styles['search-field']} type="search" placeholder={'Search customer phone number'} />
          </div>
          <div className={styles.filterWrapper}>
            <SimpleSelect label={'Stores'} isSearchable placeholder={'All Stores'} className={styles.filterInput} options={[{ label: 'All Stores', value: '' }, ...stores]} onChange={e => handleFilterChange('store', e)} />
            <SimpleSelect label={'Status'} placeholder={'All Status'} className={styles.filterInput} options={[{ label: 'All Status', value: '' }, ...STATUS_OPTIONS]} onChange={e => handleFilterChange('status', e)} />
            <div>
              <DatePicker
                showIcon={true}
                selected={filters.fromDate}
                placeholderText='From date'
                onChange={date => handleFilterChange('fromDate', date)}
                customInput={<DateCustomInput label={'From date'} />}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div>
              <DatePicker
                showIcon={true}
                selected={filters.toDate}
                placeholderText='To date'
                onChange={date => handleFilterChange('toDate', date)}
                customInput={<DateCustomInput label={'To date'} />}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </form>
      </Section>
      <Table columns={columns} items={data} isLoading={isLoading} />
      {!isLoading &&
        <div className={styles.pagination}>
          <div className={styles.paginationButtonWrapper}>
            <button type='button' className={styles.paginationButton} disabled={!page} onClick={() => setPage(page - 1)}>{i18n('Previous')}</button>
            <div className={styles.pageNumber}>{page + 1}</div>
            <button type='button' className={styles.paginationButton} disabled={data.length < DEFAULT_PAGE_SIZE} onClick={() => setPage(page + 1)}>{i18n('Next')}</button>
          </div>
        </div>
      }
      <PopUpDialog ref={dialogRef} backdropClose>
        <>
          <div className={styles.dialogContent}>
            <h3 className={styles.dialogTitle}>{dialogType === 'confirm' ? 'Confirm appointment' : 'Cancel Appointment'}</h3>
            <p className={styles.dialogText}>{`Are you sure you want to ${dialogType === 'confirm' ? 'confirm' : 'cancel'} this appointment?`}</p>
            {dialogType === 'cancel' &&
              <div className={styles.dialogInputWrapper}>
                <Input type='text' label='Reason*' placeholder='Enter reason for cancellation' value={cancelReason} onChange={e => setCancelReason(e.target.value)} />
              </div>
            }
            <div className={styles.dialogButtonWrapper}>
              <Button loading={cancelling} size='small' className={styles.dialogButton} onClick={() => dialogType === 'confirm' ?  handleConfirm(selectedAppointment) : handleCancel(selectedAppointment)}>{'Yes'}</Button>
              <Button disabled={cancelling} size='small' theme='secondary' className={styles.dialogButton} onClick={() => dialogRef.current.close()}>{'No'}</Button>
            </div>
          </div>
        </>
      </PopUpDialog>
    </div>
  )
}

StoreAppointments.propTypes = {}

export default StoreAppointments
