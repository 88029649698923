import React, { useEffect, useState } from 'react'
import HomeCard from './HomeCard'
import { Button, PredictiveSearch } from 'components'
import appointmentsIcon from './icons/appointments.svg'
import useI18n from 'common-hooks/useI18n'
import { useFetchNewestAppointmentsMutation, useLazyGetPredictedCustomersQuery, useLazyMarkAppointShowQuery } from 'app/service'
import { useNavigate } from 'react-router'
import cx from 'classnames'
import styles from './Home.module.scss'
import TabularSummary from 'components/tabular-summary/TabularSummary'
import { useSelector } from 'react-redux'
import { selectDefaults } from 'appSlice'
import CustomerPhone from './customerPhone'

function AppointmentCard() {
  const { i18n } = useI18n();
  const { storeName: storeId, country, defaultWarehouse } = useSelector(selectDefaults);
  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const [results, setResults] = useState([]);
  const [getPredictedCustomers, { isLoading }] = useLazyGetPredictedCustomersQuery();
  const [fetchNewestAppointments, { data: appointments = [] }] = useFetchNewestAppointmentsMutation();
  const [mark] = useLazyMarkAppointShowQuery();
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);
  const handleSearch = async term => {
    if (term) {
      const { data } = await getPredictedCustomers(term);
      setResults(data);
    } else {
      setResults([]);
    }
  }

  const handleSelect = email => {
    navigate(`booking/${email}`);
  }

  const handleMarkAppointmentNoShow = async appointmentId => {
    await mark({ appointmentId, show: false, country });
    fetchNewestAppointments({ storeId, country });
  }

  const handleAddPrescription = (email, appointmentId) => {
    navigate(`/customers/${email}/prescriptions`, { state: { appointmentId } });
  }

  const handleAddCEEPrescription = (email, appointmentId) => {
    navigate(`/customers/${email}/prescriptions/add-cee`, { state: { appointmentId } });
  }

  const handleNewPrescription = () => navigate('customer');

  useEffect(() => {
    if (storeId && country) fetchNewestAppointments({ storeId, country });
  }, [storeId, country])

  return (
    <HomeCard wrapperClassname={styles['card-reset']} isHighlighted focused={focused} to="appointments" icon={appointmentsIcon} title={i18n('Eye test appointment')} description={i18n('You can create and view your appointments. You have to select a customer to add an appointment')}>
      <PredictiveSearch
        onSelect={handleSelect}
        loading={isLoading}
        results={results}
        onSearch={handleSearch}
        placeholder={i18n('Search for customer')}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onNoResultsCtaClick={handleNewPrescription}
        noResultsMessage={i18n('Sorry, we cannot find any matches.')}
        noResultsCtaLabel={i18n('Add New')}
      />
      <p className={cx(styles.recent)}>Today's appointments</p>
      <div>
        <div className={cx(styles['todays-appointments'])}>
          {appointments.map(appointment => (
            <TabularSummary
              className={cx(styles['todays-appointment'])}
              title={appointment.date}
              key={appointment.id}
              footer={(
                <div className={cx(styles['appointment-ctas'])}>
                  <Button theme="secondary" size="extra-small" onClick={() => handleMarkAppointmentNoShow(appointment.id)}>{i18n('No Show')}</Button>
                  <Button theme="primary" size="extra-small" onClick={() => handleAddPrescription(appointment.customerId, appointment.id)}>{i18n('Add prescription')}</Button>
                  {defaultWarehouse?.comprehensiveETBEnabled && <Button theme="primary" size="extra-small" onClick={() => handleAddCEEPrescription(appointment.customerId, appointment.id)}>{i18n('Add CEE')}</Button>}
                </div>
              )}
              items={[
                { term: i18n('Name'), def: appointment.name },
                { term: i18n('Email'), def: appointment.email },
                { term: i18n('Phone'), def: appointment.phone },
              ]}
            />
          ))}
        </div>
      </div>
    </HomeCard>
  )
}

AppointmentCard.propTypes = {}

export default AppointmentCard
