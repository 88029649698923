import React, { useState } from 'react'
import useI18n from 'common-hooks/useI18n';
import useFields, { FIELDS } from 'common-hooks/useFields';
import { Button, InfoCard, Prescription } from 'components';
import { useWatch } from 'react-hook-form';
import { DEFAULT_VALUES } from 'components/prescription/prescription-constants';
import cx from 'classnames';
import styles from './ChildSkuForm.module.scss';
import GetPrescription from '../get-prescription/GetPrescription';
import UploadPrescription from 'components/upload-prescription/UploadPrescription';
import { expandAddons } from '../sku-form/utils';

function ChildSkuFormFields({ onSubmit, email, loading, children, width, type, material, eyeSize, childItemId, parentItemId, externalPrescription, prescriptionDoctorName, orderId, phone, customerId }) {
  const { i18n } = useI18n();
  const [noValidate, setNoValidate] = useState(false);
  const [lensType, addOnPackage, addOn1, addOn2, addOn3, addOn5, addOn6, addOn7, addOn8, index, lensDiameter, addOnColor, left, right, lensShape] = useWatch({ name: ['lensType', 'addOnPackage', 'addOn1', 'addOn2', 'addOn3', 'addOn5', 'addOn6', 'addOn7', 'addOn8', 'index', 'lensDiameter', 'addOnColor', 'left', 'right', 'lensShape'] });
  const { renderField } = useFields({ width, type, material, eyeSize, externalPrescription, prescriptionDoctorName, orderId });
  const _onSubmit = e => {
    e.preventDefault();
    onSubmit(e);
  }
  const [readonly, setReadonly] = useState(false);

  const childProductFields = {
    [i18n('Parent Id')]: parentItemId,
    [i18n('Children')]: children?.map?.(x => x.id).join(', '),
    [i18n('Lens type')]: lensType,
    [i18n('Package')]: addOnPackage,
    [i18n('Add on')]: expandAddons([addOn1, addOn2, addOn3, addOn5, addOn6, addOn7].filter(Boolean)).join(', '),
    [i18n('Index type')]: addOn8,
    [i18n('Index')]: index,
    [i18n('Lens Diameter')]: lensDiameter,
    [i18n('Frame type')]: type,
    [i18n('Add On Color')]: addOnColor,
    [i18n('Lens shape')]: lensShape,
  }

  return (
    <form onSubmit={_onSubmit} className={cx(styles.form)}>
      <InfoCard title={`${i18n('Additional details')} ${childItemId}`} data={childProductFields} loading={loading} />
      <InfoCard title={i18n('Prescription Detail')}>
        <div className={cx(styles['get-upload'])}>
          <GetPrescription email={email} phone={phone} customerId={customerId} onSelect={() => { setReadonly(false); setNoValidate(true) }} onModalClose={() => setReadonly(false)} onModalOpen={() => setNoValidate(false)} />
          {i18n('Or')}
          <UploadPrescription />
        </div>
        <div className={cx(styles.fields, styles['external-source'])}>
          {renderField(FIELDS.EXTERNAL, cx(styles.field, styles['external-field']))}
          {renderField(FIELDS.EXTERNAL_SOURCE, cx(styles.field, styles['external-source-field']))}
        </div>
        <Prescription label={i18n('Prescription')} value={DEFAULT_VALUES} disabled={readonly} addOn6={addOn6} noValidate={noValidate} />
      </InfoCard>
      <InfoCard title={i18n('Glass Properties')}>
        <div className={cx(styles.fields)}>
          {[FIELDS.CORRIDOR, FIELDS.LENS_SHAPE, FIELDS.BASE_CURVE, FIELDS.ADDON_COLOR].map(field => (
            renderField(field, cx(styles.field))
          ))}
        </div>
      </InfoCard>
      <div className={cx(styles.fields)}>
        {renderField(FIELDS.COMMENT, cx(styles.comment))}
      </div>
    </form>
  )
}

ChildSkuFormFields.propTypes = {}

export default ChildSkuFormFields
