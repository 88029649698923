import { Button, Checkbox, Countries, InfoCard, Input, SimpleSelect } from 'components';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
// import WarehouseFields from '../warehouse-fields/WarehouseFields';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useAddWarehouseMutation, useLazyWarehousesQuery, useUpdateWarehouseMutation, useWarehouseSetupQuery } from 'app/service';
import SegmentTabs from 'components/segment-tabs/SegmentTabs';
import useI18n from 'common-hooks/useI18n';
import cx from 'classnames';
import styles from './../warehouse-fields/WarehouseFields.module.scss'
import CopyIcon from './../copy-icon.svg';
import { addAlert } from 'appSlice';
import { useDispatch } from 'react-redux';

function Warehouse() {
  const navigate = useNavigate();
  const { current: days } = useRef(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']);
  const { warehouse: warehouseId } = useParams();
  const [fetch, { data = [] }] = useLazyWarehousesQuery();
  const methods = useForm({
    defaultValues: {
      storeCode: "",
      warehouseCode: "",
      warehouseName: "",
      site: "",
      city: "",
      country: "",
      area: "",
      warehouseGroup: "",
      tier: "",
      addressCode: "",
      warehouseAddress: "",
      warehouseContact: "",
      warehouseMap: "",
      warehouseLatitude: '',
      warehouseLongitude: '',
      deliveryArea: "",
      operationalHours: [
        {
          "dayOfWeek": "Sunday",
          "openingTime": "11:00",
          "closingTime": "22:00"
        },
        {
          "dayOfWeek": "Monday",
          "openingTime": "11:00",
          "closingTime": "22:00"
        },
        {
          "dayOfWeek": "Tuesday",
          "openingTime": "11:00",
          "closingTime": "22:00"
        },
        {
          "dayOfWeek": "Wednesday",
          "openingTime": "11:00",
          "closingTime": "22:00"
        },
        {
          "dayOfWeek": "Thursday",
          "openingTime": "11:00",
          "closingTime": "22:00"
        },
        {
          "dayOfWeek": "Friday",
          "openingTime": "11:00",
          "closingTime": "22:00"
        },
        {
          "dayOfWeek": "Saturday",
          "openingTime": "11:00",
          "closingTime": "22:00"
        }
      ],
      "clickAndCollectEnabled": false,
      "eyeTestBookingEnabled": false,
      "posEnabled": true
    },
    mode: 'all'
  });
  const [update,] = useUpdateWarehouseMutation();
  const [add] = useAddWarehouseMutation();
  const { data: configData } = useWarehouseSetupQuery();
  const warehouse = useMemo(() => data?.find(x => `${x.id}` === warehouseId), [data, warehouseId]);
  const fulfillmentOptions = useMemo(() => {
    const filteredWarehouseList = data.filter(x => x.warehouseType?.toLowerCase() === 'warehouse').map(wh => ({ label: wh.warehouseName, value: wh.warehouseCode }))
    filteredWarehouseList.unshift({ label: 'Select', value: '' })
    return filteredWarehouseList
  }, [data]);

  const { i18n } = useI18n();
  const [activeStep, setActiveStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formFields = {
    0: ['warehouseType', 'warehouseCode', 'warehouseName', 'warehouseNameArabic', 'operationalHours', 'warehouseGroup', 'tier', 'site', 'defaultCustomerId', 'magentoStoreCode', 'fulfilmentWarehouseCode', 'fulfilmentWarehouse'],
    1: ['eyeTestBookingEnabled', 'clickAndCollectEnabled', 'siviewEnabled', 'rtaEnabled', 'tapTryBuyEnabled', 'frontEndEnabled'],
    2: ['addressCode', 'warehouseAddress', 'warehouseAddressArabic', 'country', 'city', 'cityArabic', 'area', 'areaArabic', 'warehouseLatitude', 'warehouseLongitude', 'warehouseContact', 'storeEmail'],
  }

  const handleSubmit = async formData => {
    formData.clickAndCollectEnabled = !!formData.clickAndCollectEnabled;
    formData.eyeTestBookingEnabled = !!formData.eyeTestBookingEnabled;
    formData.warehouselatitude = formData.warehouseLatitude;
    formData.warehouselongitude = formData.warehouseLongitude;
    formData.fulfilmentWarehouseCode = formData.fulfilmentWarehouse;
    formData.posEnabled = !!formData.posEnabled;
    delete formData.createdAt;
    delete formData.updatedAt;
    delete formData.courierCode;
    delete formData.storeCodeEnums;
    delete formData.warehouseLatitude;
    delete formData.warehouseLongitude;
    formData.operationalHours = formData.operationalHours?.map(x => ({ dayOfWeek: x.dayOfWeek, openingTime: x.openingTime, closingTime: x.closingTime }));
    try {
      if (warehouse) {
        setLoading(true)
        await update({ data: formData })
        setLoading(false)
      } else {
        setLoading(true)
        let response = await add({ data: formData });
        setLoading(false)
        if (response.data.status === 'false') {
          setErrorMessage(response.data.message)
          dispatch(addAlert({ message: response.data.message }))
          return
        }
      }
      navigate('/settings/warehouses');
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
      console.log(error);
    }
  }

  const handleTimeChange = (e, day, type, value, onChange) => {
    if (type === 'opening') {
      const index = value.findIndex(x => x.dayOfWeek.toLowerCase() === day.toLowerCase());
      if (index > -1) {
        value[index].openingTime = e.target.value;
      } else {
        value.push({
          dayOfWeek: day.toLowerCase().charAt(0).toUpperCase() + day.toLowerCase().slice(1),
          openingTime: e.target.value,
        })
      }
      onChange(value);
    } else if (type === 'closing') {
      const index = value.findIndex(x => x.dayOfWeek.toLowerCase() === day.toLowerCase());
      if (index > -1) {
        value[index].closingTime = e.target.value;
      } else {
        value.push({
          dayOfWeek: day.toLowerCase().charAt(0).toUpperCase() + day.toLowerCase().slice(1),
          closingTime: e.target.value,
        })
      }
      onChange(value);
    }
  }

  const getTimeValue = (day, type, value) => {
    const index = value?.findIndex(x => x.dayOfWeek.toLowerCase() === day.toLowerCase());
    if (index > -1) {
      if (type === 'opening') {
        return value[index].openingTime;
      } else if (type === 'closing') {
        return value[index].closingTime;
      }
    }
  }

  const handleCopyToAllDays = (day, value, onChange) => {
    const newValue = [];
    const index = value?.findIndex(x => x.dayOfWeek.toLowerCase() === day.toLowerCase());
    if (index > -1) {
      const openingTime = value[index].openingTime;
      const closingTime = value[index].closingTime;
      if (!openingTime || !closingTime) return;
      days.forEach(day => {
        newValue.push({
          dayOfWeek: day.toLowerCase().charAt(0).toUpperCase() + day.toLowerCase().slice(1),
          openingTime,
          closingTime,
        })
      })
      onChange(newValue);
    }
  }

  const handleTabClick = async (index) => {
    if (!warehouse && (index > activeStep)) {
      let validation = await methods.trigger(formFields[activeStep]);
      if (!validation) return;
    }
    setActiveStep(index);
    setErrorMessage('');
  }

  const handleButtonPress = async (e) => {
    e.preventDefault();
    let validation = false
    if (activeStep === 2) {
      validation = await methods.trigger([...formFields[0], ...formFields[1], ...formFields[2]])
    } else {
      validation = await methods.trigger(formFields[activeStep]);
    }
    if (!validation) return;
    setErrorMessage('');
    if (activeStep === 2) return methods.handleSubmit(handleSubmit)();
    setActiveStep(activeStep + 1);
  }

  useEffect(() => {
    if (warehouse) {
      const copyWareHouse = { ...warehouse }
      const opHours = copyWareHouse.operationalHours.filter(op => op.storeCode === copyWareHouse.storeCode)
      copyWareHouse.operationalHours = opHours
      copyWareHouse.warehouseType = copyWareHouse.warehouseType?.toLowerCase();
      copyWareHouse.country = copyWareHouse.magentoStoreCode?.split('-')?.[0];
      methods.reset(copyWareHouse);
    }
  }, [warehouse]);

  useEffect(() => {
    fetch();
  }, [])

  return (
    <FormProvider {...methods}>
      <div className={styles.heading}>
        <h2>{i18n('Warehouse Detail')}</h2>
      </div>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SegmentTabs active={activeStep} setActive={handleTabClick}
          items={[
            { label: i18n('Details') },
            { label: i18n('Module Access') },
            { label: i18n('Delivery') },
          ]}
        />
        {activeStep === 0 &&
          <InfoCard title={i18n('Details')}>
            <div className={cx(styles.fields)}>
              <Controller
                name="storeCode"
                rules={{ required: i18n('Store code is required'), }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Store code')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="warehouseCode"
                rules={{ required: i18n('Warehouse code is required'), }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Warehouse code')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="magentoStoreCode"
                rules={{ required: i18n('Magento store code is required'), }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <SimpleSelect label={i18n('Magento Store code')} className={cx(styles['warehouse-type'])} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} options={[
                    { label: 'Select', value: '' },
                    { label: 'sa-en', value: 'sa-en' },
                    { label: 'bh-en', value: 'bh-en' },
                    { label: 'ae-en', value: 'ae-en' },
                    { label: 'kw-en', value: 'kw-en' },
                    { label: 'om-en', value: 'om-en' },
                    { label: 'saopcl-en', value: 'saopcl-en' },
                  ]} />
                )}
              />
              <Controller
                name="warehouseName"
                rules={{ required: i18n('Warehouse name is required'), }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Warehouse name (EN)')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="warehouseNameArabic"
                rules={{ required: i18n('Warehouse name in arabic is required'), }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Warehouse name (AR)')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="defaultCustomer"
                rules={{ required: i18n('Customer ID is required'), }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Default Customer ID')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="warehouseType"
                // rules={{ required: i18n('Warehouse type is required'), }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <SimpleSelect label={i18n('Warehouse type')} className={cx(styles['warehouse-type'])} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} options={[
                    { label: 'Select', value: '' },
                    { label: 'Warehouse', value: 'warehouse' },
                    { label: 'Store', value: 'store' },
                  ]} />
                )}
              />
              {configData?.setup?.length > 0 && configData?.setup?.map((config, index) => (
                <Controller
                  key={index}
                  name={config.setupKey}
                  rules={{ required: i18n(`${config.setupKey} is required`), }}
                  render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                    <SimpleSelect
                      label={i18n(config.setupKey.charAt(0).toUpperCase() + config.setupKey.slice(1))}
                      className={cx(styles['warehouse-type'])}
                      error={error?.message}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      options={[{ label: 'Select', value: '' }, ...config.values.map(x => ({ label: x.name, value: x.name }))]} />
                  )}
                />
              ))}
              <Controller
                name="fulfilmentWarehouse"
                rules={{ required: i18n('FulFillment Warehouse Code is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <SimpleSelect label={i18n('Fulfillment Warehouse Code')} className={cx(styles['warehouse-type'])} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} options={fulfillmentOptions} />
                )}
              />
            </div>
            <label className={cx(styles.operationalHoursLabel)} htmlFor={'operationalHours'}>{'Working hours'}</label>
            <div className={cx(styles.fields)}>
              <Controller
                name="operationalHours"
                // rules={{ required: i18n('Operational hours are required'), validate: { validate: v => v?.length === 7 || i18n('Operational hours are required') } }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <div>
                    {days.map((day, index) => (
                      <div key={index} className={styles.dayInput}>
                        <label className={styles.dayInputLabel}>{day}</label>
                        <Input type="time" className={cx(styles.map)} error={error?.message} onBlur={onBlur} onChange={(e) => handleTimeChange(e, day, 'opening', value, onChange)} value={getTimeValue(day, 'opening', value)} />
                        <Input type="time" className={cx(styles.map)} error={error?.message} onBlur={onBlur} onChange={(e) => handleTimeChange(e, day, 'closing', value, onChange)} value={getTimeValue(day, 'closing', value)} />
                        <img title='Copy to all days' src={CopyIcon} alt="Copy" className={styles.copyIcon} onClick={() => handleCopyToAllDays(day, value, onChange)} />
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
          </InfoCard>
        }

        {activeStep === 1 &&
          <InfoCard title={i18n('Module Access')}>
             <div className={cx(styles.fields)}>
              <Controller
                name="posEnabled"
                render={({ field: { value, onBlur, onChange }, fieldState: { error, name } }) => (
                  <Checkbox className={cx(styles['click-and-collect'])} name="posEnabled" value="true" checked={value} label={i18n('Is Store Enabled?')} error={error?.message} onBlur={onBlur} onChange={onChange} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="eyeTestBookingEnabled"
                render={({ field: { value, onBlur, onChange }, fieldState: { error, name } }) => (
                  <Checkbox className={cx(styles['eye-test-booking'])} name="eyeTestBookingEnabled" value="eyeTestBookingEnabled" checked={value} label={i18n('Enabled for eye test booking')} error={error?.message} onBlur={onBlur} onChange={onChange} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="clickAndCollectEnabled"
                render={({ field: { value, onBlur, onChange }, fieldState: { error, name } }) => (
                  <Checkbox className={cx(styles['click-and-collect'])} name="clickAndCollectEnabled" value="clickAndCollectEnabled" checked={value} label={i18n('Click and collect')} error={error?.message} onBlur={onBlur} onChange={onChange} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="siviewEnabled"
                render={({ field: { value, onBlur, onChange }, fieldState: { error, name } }) => (
                  <Checkbox className={cx(styles['click-and-collect'])} name="siviewEnabled" value="true" checked={value} label={i18n('Siview Enabled')} error={error?.message} onBlur={onBlur} onChange={onChange} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="rtaEnabled"
                render={({ field: { value, onBlur, onChange }, fieldState: { error, name } }) => (
                  <Checkbox className={cx(styles['click-and-collect'])} name="rtaEnabled" value="true" checked={value} label={i18n('RTA enabled')} error={error?.message} onBlur={onBlur} onChange={onChange} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="tapTryBuyEnabled"
                render={({ field: { value, onBlur, onChange }, fieldState: { error, name } }) => (
                  <Checkbox className={cx(styles['click-and-collect'])} name="tapTryBuyEnabled" value="true" checked={value} label={i18n('Enabled for tap try buy ')} error={error?.message} onBlur={onBlur} onChange={onChange} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="frontEndEnabled"
                render={({ field: { value, onBlur, onChange }, fieldState: { error, name } }) => (
                  <Checkbox className={cx(styles['click-and-collect'])} name="frontEndEnabled" value="true" checked={value} label={i18n('Show on FE Website and App')} error={error?.message} onBlur={onBlur} onChange={onChange} />
                )}
              />
            </div>
          </InfoCard>
        }

        {activeStep === 2 &&
          <InfoCard title={i18n('Delivery')}>
            <div className={cx(styles.fields)}>
              <Controller
                name="warehouseContact"
                rules={{  required: i18n('Phone is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input type="number" label={i18n('Phone')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="storeEmail"
                rules={{ validate: v => v?.length > 0 || i18n('Email is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Email')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="addressCode"
                // rules={{ required: i18n('Address code is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Address code')} error={error?.message} className={cx(styles['address-code'])} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="warehouseAddress"
                rules={{ required: i18n('Address is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Address (EN)')} error={error?.message} className={cx(styles.address)} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="warehouseAddressArabic"
                // rules={{ required: i18n('Address in arabic is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Address (AR)')} error={error?.message} className={cx(styles.address)} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="country"
                rules={{ required: i18n('Country is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Countries className={cx(styles.countries)} label={i18n('Country')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="city"
                rules={{ required: i18n('City is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('City (EN)')} className={cx(styles.city)} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="cityArabic"
                rules={{ required: i18n('City in arabic is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('City (AR)')} className={cx(styles.city)} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="area"
                rules={{ required: i18n('Area is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Area (EN)')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />

              <Controller
                name="areaArabic"
                rules={{ required: i18n('Area in arabic is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Area (AR)')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                name="warehouseLatitude"
                rules={{ required: i18n('Latitude is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Latitude')} className={cx(styles.map)} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="warehouseLongitude"
                rules={{ required: i18n('Longitude is required') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Longitude')} className={cx(styles.map)} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="googlePlaceId"
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Google place id')} className={cx(styles.map)} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name="warehouseMap"
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input label={i18n('Map location')} className={cx(styles.map)} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
            </div>
          </InfoCard>
        }
        <div className={cx(styles.fields)}>
          <Button theme='secondary' type="button" onClick={() => navigate('/settings/warehouses')}>{i18n('Cancel')}</Button>
          <Button loading={loading} type={activeStep === 2 ? 'submit' : 'button'} onClick={handleButtonPress}>{activeStep === 2 ? 'Submit' : 'Next'}</Button>
        </div>
        <span className={styles['error-info']}>{errorMessage}</span>
      </form>
    </FormProvider>
  )
}

export default Warehouse;
