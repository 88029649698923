
import React, { useState } from 'react';
import { Button, Checkbox, FormSectionTitle, Input } from 'components';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import styles from './../Questionnaire.module.scss'

const ThankYou = () => {
  const navigate = useNavigate();
  const { customerId } = useParams()
  const [formData, handleNext] = useOutletContext();
  const [customerSubmit, setCustomerSubmit] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!customerSubmit) {
      setCustomerSubmit(true)
      return
    }
    if(customerId) {
      navigate(`/customers/${customerId}/prescriptions`)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {!customerSubmit ? (
        <>
         <div className={styles.readyForTestMessage}>{`Hey ${formData?.customer?.firstName} ${formData?.customer?.lastName},`}</div>
      <div className={styles.readyForTestMessage}>{`Thank you for providing the information.`}</div>
      <div className={styles.readyForTestMessage}>{`Are you ready for your eye test?`}</div>
        </>
      ) : (
        <div className={styles.readyForTestMessage}>{'Don’t forget to clean your eyetest machine, wash your hands in front of the customer and thank the customer after eyetest!'}</div>
      )}
      <Button className={styles.readyForTestButton} type='submit'>{!customerSubmit ? 'Yes' : 'Okay'}</Button>
    </form>
  );
};

export default ThankYou;
